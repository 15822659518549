import { Vue, Component } from "vue-property-decorator";
import { BButton, BCard, BLink, BCardTitle, BCardText, BTooltip } from "bootstrap-vue";
import { IAuthLoginResultDto, ICompany, ICompanyCreateDto, IUser } from "@lib";
import AltLogo from "@/core/components/alt-logo";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { Button, Icon, List } from "@core/components/alt-ui/controls";
import { ModalComponent } from "@core/components/alt-ui/modal";
import { CompanyCreateModal, CompanyCreateModalAnswer, ICompanyCreateModalData } from "./company-create.modal";

@Component({
    components: {
        BCard,
        BCardTitle,
        BCardText,
        BLink,
        BButton,
        BTooltip,
        AltLogo,
        ControlComponent,
        ModalComponent,
    },
})
export default class Login extends Vue {
    private resources = {
        LabelTitleText: "ВЫБОР КОМПАНИИ",
        LabelSubtitle1Text: "Выберите компанию:",
        LabelNoCompanies: "Нет компаний",
        ButtonCreateText: "Создать новую компанию",
        ButtonLogoutText: "Выход из системы",
    };

    private AuthUserOnlyUseCase = this.$alt.system.usecase.createAuthUserUseCase();

    private user!: IUser;
    private listCompanies: List<ICompany>;
    private companyCreateModal: CompanyCreateModal;

    public constructor() {
        super();

        this.listCompanies = this.createList();
        this.companyCreateModal = new CompanyCreateModal();
    }

    private createList(): List<ICompany> {
        const list = new List<ICompany>();
        list.header = c => c.info.name.toUpperCase();
        list.content = c => c.info.description ?? "Без описания";
        list.left = c => {
            const icon = new Icon();
            icon.class = "mr-0.5";
            icon.icon = "HomeIcon"; // iconPack: "alt", // icon: "office",
            return icon;
        };
        list.right = c => {
            const btnEnter = new Button();
            btnEnter.text = "Войти";
            btnEnter.variant = "flat-primary";
            btnEnter.addClickHandler(() => this.login(c.id));
            return btnEnter;
        };
        return list;
    }

    public async mounted(): Promise<void> {
        return this.init();
    }

    private async init(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.user = await this.$info.getUser();
            await this.selectData();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async selectData(): Promise<void> {
        const companies = await this.AuthUserOnlyUseCase.companies();
        this.listCompanies.items = companies;
    }

    private async showCreateModal(): Promise<void> {
        const result = await this.companyCreateModal.show();

        if (result?.answer === CompanyCreateModalAnswer.Create && result.data) {
            await this.createCompany(result.data);
        }
    }

    private async createCompany(data: ICompanyCreateModalData): Promise<ICompany | null> {
        try {
            this.$alt.loader.show();

            const dto: ICompanyCreateDto = {
                name: data.name,
                description: data.description,
                email: this.user.info.contacts.email,
                phone: this.user.info.contacts.phone,
                owner: this.user.id,
                ownerPosition: "Учредитель",
                // features?: {
                //     [CompanyFeature.Offices]?: ICompanyFeatureOffices;
                //     [CompanyFeature.Shops]?: ICompanyFeatureShops;
                //     [CompanyFeature.Barcodes]?: ICompanyFeatureBarcodes;
                // },
                // pricingPlan?: CompanyPricingPlan;
                // meta?: any;
            };

            const order = await this.AuthUserOnlyUseCase.createCompany(dto);
            await this.selectData();
            return order;
        } catch (e: any) {
            this.$alt.toast.error(`Не удалось создать новую компанию:\n${e.message}`);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async logout(): Promise<void> {
        const yes = await this.$alt.message.confirm("Вы уверены, что хотите выйти?", "Выход", {
            acceptText: "Да",
            cancelText: "Нет",
            color: "primary",
        });

        if (yes) {
            await this.doLogout();
        }
    }

    private async doLogout(): Promise<void> {
        try {
            this.$secure.reset();
            this.$info.clean();
            this.$alt.system.token.cleanData();

            await this.$router.push({ name: "login" }).catch(() => {});
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async login(companyId: string): Promise<void> {
        try {
            this.$alt.loader.show();

            const result = await this.AuthUserOnlyUseCase.login(companyId);
            this.applyCompanyData(result);

            await this.$router.push({ name: "orders", query: { notification: "1" } });
            //await this.$router.push({ name: "orders" });
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async applyCompanyData(result: IAuthLoginResultDto): Promise<void> {
        this.$alt.removeFromLocalStorage("companyType");
        this.$alt.removeFromLocalStorage("partner");
        this.$alt.removeFromLocalStorage("meta");

        this.$alt.system.token.cleanData();
        this.$alt.system.token.setCompanyToken(result.accessToken);

        this.$info.setAuthInfo(result.data);
        this.$settings.setUserInfo(result.data.user.user.id);
        if (result.data.company) {
            this.$secure.grant(result.data.company.permissions);
        }
    }
}
